import _ from 'lodash'
import $ from 'jquery'
import * as Sentry from '@sentry/react'
import moment from 'moment-timezone'
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Cookie from 'js-cookie'

import apis from 'browser/app/models/apis'
import { browserHistory } from 'browser/history'
import { clearCookie } from 'browser/app/utils/utils'
import { getAuthorization, normalizeRedirectPath } from 'browser/app/utils/auth'
import { Settings } from 'browser/app/models/settings'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Logger } from 'browser/apis/logging'

import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

import { Header } from '../components/header/header'
import { Mobile404 } from './mobile-404'
import { IMobileEntityPageProps, MobileEntityPage } from './mobile-entity'
import { DeepLink } from './deep-link'
import { TruckLoader } from '../components/truck-loader/truck-loader'
import { MobileError } from './mobile-error'
import { getError } from 'shared-libs/models/utils'
import { MobileWorkflowList } from './mobile-workflow-list'
import { LocalStorageProvider } from 'browser/contexts/local-storage/local-storage-provider'
import { InboxProvider } from 'browser/contexts/inbox/inbox-provider'

const SentryRoute = Sentry.withSentryRouting(Route)

interface IMobileAppNavigatorState {
  error?: any
  isLoading?: boolean
  settings?: Settings
}

export class MobileAppNavigator extends React.Component<any, IMobileAppNavigatorState> {
  private ref = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoading: true,
      settings: null,
    }
    OverlayManager.pushReactHost(this)
  }

  componentDidMount() {
    this.installAjaxHooks()
    this.bootstrap()
  }

  componentWillUnmount(): void {
    this.removeAjaxHooks()
    OverlayManager.popReactHost()
  }

  render() {
    const { isLoading, error, settings } = this.state

    if (isLoading) {
      return <TruckLoader />
    } else if (error) {
      return renderError(error)
    }
    return (
      <LocalStorageProvider>
        <InboxProvider inboxConfig={settings.getInboxConfiguration()} userId={_.get(settings.getUser(), 'uniqueId')}>
          <AppNavigatorContext.Provider value={{ settings: settings }}>
            <Header />
            <Switch ref={this.ref}>
              <SentryRoute path="/entity/:entityId" render={renderEntityPage} />
              <Redirect from="/workflow/:entityId" to={ { pathname: "/entity/:entityId", search: location.search } }/>
              <SentryRoute path="/actions/entity/create" component={DeepLink} />
              <SentryRoute path="/workflow-list" component={MobileWorkflowList} />
              <SentryRoute exact={true} render={this.renderRedirectRoute} />
            </Switch>
          </AppNavigatorContext.Provider>
        </InboxProvider>
      </LocalStorageProvider>
    )
  }

  private bootstrap = async () => {
    const authToken = getAuthorization()
    Cookie.set('Authorization', authToken)

    try {
      apis.setupAuthorization(authToken)
      const appSettings = new Settings(moment.tz.guess(), PlatformType.MOBILE_WEB)
      await appSettings.initialize()
      this.setState({ settings: appSettings })
    } catch (err) {
      this.setState({ error: err })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  private renderRedirectRoute = () => {
    // TODO: Add a route override in settings that is  specific to mobile-web,
    // as the existing `initialRoute` in the bundle is specific to webapp and
    // not relevant in the mobile-web application.

    // const initialRoute = settings.getInitialRoute()
    const initialRoute = '/workflow-list'
    return <Redirect to={initialRoute} />
  }

  private installAjaxHooks() {
    const authorization = getAuthorization()
    apis.setupAuthorization(authorization)
    // setup global error handler for 401 redirection
    $(document).ajaxError((event, jqxhr, settings, error) => {
      const isOnSignIn = window.location.pathname.indexOf('/sign-in') !== -1
      // no need to redirect the user if they are already on a sign in page
      if (jqxhr.status === 401 && !isOnSignIn) {
        // log to help debug erroneous 401s, e.g. a report in VD-10433 of a mweb
        // user tapping a valid link in SMS and unexpectedly routed to sign-in.
        Logger.logEvent('Unauthorized Request Attempted', { url: jqxhr.url, location: location.href })

        clearCookie('Authorization')
        browserHistory.replace({
          pathname: '/sign-in',
          search: location.search,
          state: { nextRoute: normalizeRedirectPath(window.location) }
        })
      }
    })
  }

  private removeAjaxHooks() {
    $.ajaxSetup({
      beforeSend: null,
      cache: false,
      error: null,
    })
  }
}

function renderEntityPage(props: IMobileEntityPageProps) {
  const entityId = props.match?.params?.entityId

  if (_.isNil(entityId) || entityId === 'undefined') {
    Sentry.captureException(new Error('attempted to navigate to undefined entity'))
    return <SentryRoute component={Mobile404} />
  }
  return <MobileEntityPage {...props} />
}


function renderError(error) {
  if (error.responseJSON?.statusCode === 401) {
    // handled by a redirect, no need to show anything
    return null
  } else {
    const exception = getError(error)
    Sentry.captureException(exception)
    const description = 'An error has occurred while loading this page. Please try reloading the page to resolve the issue.'
    return <MobileError title="Error" description={description} />
  }
}
