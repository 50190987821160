import _ from 'lodash' 
import React from 'react'
import { Classes, Intent, Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import './_header.scss'
import './_menu.scss'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import {
  IMobileHeaderButtonState,
  IMobileHeaderContext,
  MobileHeaderContext,
} from 'browser/mobile/contexts/mobile-header-context'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Menu } from './menu'

const VectorLogo = require('images/onboarding/vector-logo-horizontal.svg')

interface IHeaderState {
  menuOpen?: boolean
  chatOpen?: boolean
}

export class Header extends React.Component<any, IHeaderState> {

  render() {
    return <MobileHeaderContext.Consumer>{this.renderHeader}</MobileHeaderContext.Consumer>
  }

  private renderHeader = (context: IMobileHeaderContext) => {
    const { enabled, title, chatButton } = context

    if (!enabled) {
      return null
    }

    return (
      <header className="mobile-header mobile-header--slideDown">
        {this.renderMenuButton()}
        {this.renderExpander()}
        {this.renderTitle(title)}
        {this.renderChatButton(chatButton)}
      </header>
    )
  }

  private renderMenuButton() {
    // TODO: re-enable once VD-10479 (improved new user UX) and VD-10355
    // (improve web auth token handling) are complete. This was originally added
    // in VD-7666, but it lead to a problem where some users were logging out on
    // mweb and couldn't get back into the previous SMS links.
    return null

    const tetherOptions = {
      attachment: 'top right',
      targetAttachment: 'bottom right',
    }
    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <TetherTarget
            closeOnPortalClick={true}
            tethered={<Menu settings={settings} />}
            tetherOptions={tetherOptions}
          >
            <Button
              intent={Intent.PRIMARY}
              data-debug-id="headerMenuButton"
              className={classNames('mobile-header__button', Classes.iconClass(IconNames.MENU))}
            />
          </TetherTarget>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderExpander() {
    return <span className="flex-grow" />
  }

  private renderTitle(title: React.ReactNode | null | undefined) {
    if (title === null) {
      // explicit null value hides the title
      return null
    } else if (title === undefined) {
      return (
        <span className="mobile-header__title-container">
          <img className="mobile-header__logo" src={VectorLogo} />
        </span>
      )
    } else if (_.isString(title)) {
      return (
        <span className="mobile-header__title-container">
          <Tooltip content={title} position={Position.BOTTOM}>
            <h1 className="mobile-header__title">{title}</h1>
          </Tooltip>
        </span>
      )
    } else {
      return (
        <span className="mobile-header__title-container">
          <h1 className="mobile-header__title">{title}</h1>
        </span>
      )
    }
  }

  private renderChatButton(chatButton: IMobileHeaderButtonState | null | undefined) {
    if (_.isNil(chatButton)) {
      // any nil value hides the chat button
      return null
    }
    const className = chatButton?.className ?? Classes.iconClass(IconNames.CHAT)
    return (
      <Button
        intent={Intent.PRIMARY}
        data-debug-id="headerChatButton"
        className={classNames('mobile-header__button', className)}
        onClick={chatButton?.handler}
      />
    )
  }
}
